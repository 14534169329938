<template>
  <div id="policyTsft" >
     <div class="about" id="warp">
      <div class="company-details">
        <div class="container">
          <b>1.便捷资讯</b><br/>
          · 突发行情提醒<br/>
          · 热点新闻<br/>
          · 客户教育<br/>
          · 获利机会提醒<br/>
          · 操盘策略<br/>
          · 本周财经日历及周重点行情预告<br/>
          · 本周行情回顾<br/>
          <b>2.账户和交易安全相关</b><br/>
          · 账户分析<br/>
          · 节假日休市安·排及冬夏令时变更<br/>
          · 大行情波动，仓位提醒<br/>
          · 账户降级预警<br/>
          <b>3.活动提醒</b><br/>
          · 新用户活动提醒<br/>
          · 老用户活动提醒<br/>
        </div>
      </div>      

    </div>

  </div>
</template>

<script>
import eventBusMitter from '../../../utils/eventbus';
export default {
  name: 'email',
  data(){
    return{
     
    }
  },
  created(){
     eventBusMitter.emit('eventBus', {key:true})
  },
  mounted() {
   
  },
  methods:{

  }
}
</script>

<style lang="less" scope>

      @charset "utf-8";
      a,
      button {
        -webkit-appearance: none;
        border-radius: 0;
        border: none;
        outline: 0;
      }

      body * {
        font-family: "microsoft yahei", Helvetica;
      }

      body {
        -webkit-text-size-adjust: 100%;
        background: #fff;
        margin: 0;
        padding: 0;
        -webkit-user-select: none;
        color: #515151;
        font-size: unset;
      }

      div,
      h1,
      p,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      li,
      span,
      input,
      p,
      a,
      table,
      th,
      tr td {
        margin: 0;
        padding: 0;
      }

      html,
      body {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }


      .contact_item_cont ::-webkit-input-placeholder {
        color: #ccc;
      }

      .contact_item_cont ::-moz-placeholder {
        color: #ccc;
      }

      .contact_item_cont :-ms-input-placeholder {
        color: #ccc;
      }

      .contact_item_cont input:-moz-placeholder {
        color: #ccc;
      }

      .container {
        padding: 0 4%;
        background: #fff;
      }
      .container b{
        font-size: 14px;
      }
      .fl {
        float: left;
      }

      .fr {
        float: right;
      }

      .fix:after {
        content: "";
        display: block;
        clear: both;
      }

      .fix {
        zoom: 1;
      }

      .hide {
        display: none !important;
      }

      .text-left {
        text-align: left;
      }

      .text-right {
        text-align: right;
      }


      /**主体内容**/
      #warp {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }

      #warp.navpanel-open {
        width: 100%;
        position: fixed;
        overflow: hidden;
      }

.company-details {
  font-size: 12px;
  color: #515151;
  padding: 1rem 0;
  line-height: 24px;
  font-size: 12px;
}

.company-details p {
  line-height: 20px;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.privacy_policy p span {
  /* display: inline-block;
    vertical-align: top; */
}
#policyTsft{
  font-size: 12px!important;
}
</style>
